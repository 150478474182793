
import React from 'react';

import MarkdownView from '../Markdown/MarkdownView';
import Image from 'next/image';
import FeaturedBrands from '../FeaturedBrands';
import UniqueSellingPoints from '../UniqueSellingPoints';
import PredefinedComponent from './PredefinedPageComponents/PredefinedComponent';

export default function SitePageComponent({item}) {
  if (!item) {
    return null;
  }
  switch (item.__component) {
    case "page-components.predefined-page-component":
      return <PredefinedComponent name={item.name} settings={item.settings}/>
    case "page-components.rich-text":
      return <MarkdownView>{item.text}</MarkdownView>
    case "page-components.media":
      return (
        <Image
          width={item.width}
          height={item.height}
          objectFit={'contain'}
          src={item.src}
        />)
    case "page-components.featured-brands":
      return (
        <FeaturedBrands
          brands={item.brands}
          card={item.card}
          cardMobile={item.card_mobile}
        />
      )

    case "page-components.unique-selling-point":
      return  <UniqueSellingPoints points={item.points}/>
    default:
      return null;
  }
}