import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from 'next/link'
import { getProducerLocationStr } from '@/utils/location'
import { Mixpanel } from '@/utils/mixpanel'
import Image from 'next/image'
import { makeBrandUrl } from '@/src/utils/urlGenerator'

const attributes = {
  'Image above text': {
    diretion: 'column',
    maxWidthText: '100%',
    mediaFirst: true,
  },
  'Text above image': {
    diretion: 'column',
    maxWidthText: '100%',
    mediaFirst: false,
  },
  'Image on the right': {
    diretion: 'row',
    maxWidthText: '100%',
    mediaFirst: false,
  },
  'Image on the left': {
    diretion: 'row',
    maxWidthText: '100%',
    mediaFirst: true,
  }
} 
const BrandCardWithText = ({
  brand,
  clickable = true,
  locRef,
  width = '100%',
  card
}) => {
  const theme = useTheme()
  const mainColor = theme.palette.primary.main

  const showLocation = card?.show_location;
  const showTitle = card?.show_title;
  const { diretion, maxWidthText, mediaFirst } =
    attributes[card?.layout] || attributes['Image above text']
  const imageHeight = card?.image_height ||'200px';

  const locationStr = showLocation && getProducerLocationStr(brand)

  const brandLink = makeBrandUrl({slug: brand.slug, locRef})
  const cardMedia = brand.card_image && (
    <CardMedia
      title={brand.title}
      sx={{
        height: imageHeight,
        position: 'relative',
        flexGrow: 2,
        width: '100%'
      }}
    >
      <Image
        layout="fill"
        src={brand.card_image}
        alt="brand image"
        objectFit="cover"
      />
    </CardMedia>
  );
  const cardContent = (
    <Box
      component={CardContent}
      sx={{
        maxWidth: maxWidthText,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom={locationStr ? undefined : true}
        color={mainColor}
        align={'center'}
        component="p"
      >
        {brand.name}
      </Typography>
      {locationStr && (
        <Typography
          variant="title1"
          gutterBottom
          color={'text.secondary'}
          align={'center'}
          component="p"
        >
          {locationStr}
        </Typography>
      )}
      {showTitle && (
        <Box
          component={Typography}
          variant={'h6'}
          fontWeight={500}
          align={'left'}
          gutterBottom
        >
          {brand.title}
        </Box>
      )}
    </Box>
  )
  return (
    <Link href={clickable ? brandLink : ''}>
      <a>
        <Box
          display={'block'}
          width={width}
          height={'100%'}
          sx={{
            marginTop: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'all .2s ease-in-out',
            '&:hover': {
              transform: `translateY(-${theme.spacing(1 / 2)})`,
            },
          }}
          onClick={() =>
            Mixpanel.track('Brand card click', {
              brand: brand.key,
              locRef,
            })
          }
        >
          <Box
            component={Card}
            width={'100%'}
            height={'100%'}
            borderRadius={3}
            position={'relative'}
            display={'flex'}
            flexDirection={diretion}
            alignItems={'center'}
            sx={{ backgroundColor: theme.palette.background.level2 }}
            // display={'flex'}
            // flexDirection={'column'}
            // justifyContent={'flex-start'}
          >
            {mediaFirst ? (
              <>
                {cardMedia}
                {cardContent}
              </>
            ) : (
              <>
                {cardContent}
                {cardMedia}
              </>
            )}
          </Box>
        </Box>
      </a>
    </Link>
  )
}


export default BrandCardWithText
