import React from 'react'
import { BrandCardWithText } from '.'

const BrandCard = ({
  brand,
  card,
  locRef,
  width = '100%',
}) => {
  return (
    <BrandCardWithText
      card={card}
      width={width}
      brand={brand}
      locRef={locRef}
    />
  )
}

export default BrandCard
