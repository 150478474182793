import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import MarkdownView from '../Markdown/MarkdownView';
import Image from 'next/image';

const SipjoyHighlights = ({points}) => {
  const theme = useTheme();

  if (!points) {
    return null;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        {points.map((item, i) => (
          <Grid item xs={12} md={12 / points.length} key={i}>
            <Box
              width={1}
              height={'100%'}
              data-aos={'fade-up'}
              padding="0 20px"
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{ textAlign: 'center' }}
              >
                {item.icon?.src && (
                  <Box
                    component={Avatar}
                    width={60}
                    height={60}
                    marginBottom={2}
                  >
                    <Image src={item.icon?.src} width={60} height={60} />
                  </Box>
                )}
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <MarkdownView color={theme.palette.text.secondary}>
                  {item.subtitle}
                </MarkdownView>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
};

export default SipjoyHighlights;
