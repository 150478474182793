import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import BrandCard from '../BrandCard';

import { MeetWineriesButton } from '../Buttons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const FeaturedBrands = ({ brands, card, cardMobile, showExploreMoreButton }) => {
  if (!brands) {
    return
  }
  const theme = useTheme()
  const isMd= useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  let cardToUse = isMd ? card : (cardMobile || card);
  //const [validProducers] = separateBrandsByStateShipment(brands)
  const brandsComp = (
    <Box
      display={'flex'}
      flexDirection={cardToUse?.cards_direction === 'row' ? 'row' : 'column'}
      alignItems={'stretch'}
      gap={5}
      sx={{ padding: '30px 20px', width: '100%' }}
    >
      {brands.map((brand, i) => (
        <Box key={i} data-aos="flip-left" sx={{ flexGrow: 2 }}>
          <BrandCard
            brand={brand}
            card={cardToUse}
            locRef="highlightedProducers"
          />
        </Box>
      ))}
    </Box>
  )
  if (showExploreMoreButton) {
    return (
      <Box>
        {brandsComp}
        {showExploreMoreButton && (
          <Box
            marginTop={3}
            display={'flex'}
            justifyContent={'center'}
            data-aos="fade-up"
          >
            <Box sx={{ width: '400px' }}>
              <MeetWineriesButton
                text="See All Winemakers"
                url="/brands?ref=highlighted_producers_all"
              />
            </Box>
          </Box>
        )}
      </Box>
    )
  }
  return brandsComp; 
}

FeaturedBrands.propTypes = {
  brands: PropTypes.array.isRequired,
};

export default FeaturedBrands;
