import React from 'react';

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Link from 'next/link'
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LiquorIcon from '@mui/icons-material/Liquor';
import QuizIcon from '@mui/icons-material/Quiz';
import LocalBarIcon from '@mui/icons-material/LocalBar';

export const HomeButton = ({text, link, icon}) => {
  return (
    <Link href={link}>
      <a style={{ display: 'block', width: '100%' }}>
        <Button
          fullWidth
          startIcon={icon}
          sx={{ maxWidth: '425px', padding: '15px' }}
          variant="contained"
          color="primary"
          size="large"
        >
          {text}
        </Button>
      </a>
    </Link>
  )
};


HomeButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export const MeetWineriesButton = ({text, link}) => {
  return HomeButton({
    text: text || 'Meet Winemakers',
    link: link || '/brands?ref=hero_cta',
    icon: <AgricultureIcon />,
  });
};
export const ShopWineButton = ({text, link}) => {
  return HomeButton({
    text: text || 'Shop Wines',
    link: link || '/wines?ref=hero_cta',
    icon: <LiquorIcon />,
  });
};

export const ShopSpiritButton = ({text, link}) => {
  return HomeButton({
    text: text || 'Shop Spirits',
    link: link || '/spirits?ref=hero_cta',
    icon: <LocalBarIcon />,
  });
};


export const QuizButton = ({text, link}) => {
  return HomeButton({
    text: text || 'Find your perfect wine',
    link: link || '/quiz?ref=hero_cta',
    icon: <QuizIcon />,
  });
};
